import React from "react"
import { Link } from "gatsby"

const isActive = ({ isCurrent }) => {
  const common =
    "h-full ml-8 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out"
  const active = "border-indigo-500 text-gray-900 focus:border-indigo-700"
  const inactive =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
  return isCurrent
    ? {
        className: `${common} ${active}`,
      }
    : {
        className: `${common} ${inactive}`,
      }
}

type Props = {
  to: string
  children: React.ReactNode
}

export function NavLink({ to, children }: Props) {
  return (
    <Link to={to} getProps={isActive}>
      {children}
    </Link>
  )
}
