import React from "react"
import { Link } from "gatsby"

const isActive = ({ isCurrent }) => {
  const common =
    "mt-1 block pl-3 pr-4 py-2 border-l-4 text-base font-medium focus:outline-none transition duration-150 ease-in-out"
  const active =
    "border-indigo-500 text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700"
  const inactive =
    "border-transparent text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300"
  return isCurrent
    ? {
        className: `${common} ${active}`,
      }
    : {
        className: `${common} ${inactive}`,
      }
}

type Props = {
  to: string
  children: React.ReactNode
}

export function MobileNavLink({ to, children }: Props) {
  return (
    <Link to={to} getProps={isActive}>
      {children}
    </Link>
  )
}
